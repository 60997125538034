import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api, { APIs } from "../../Api";

interface Props {
  openDrawer: boolean;
  handleClose: () => void;
}

function CompareDrawer({ openDrawer, handleClose }: Props) {
  const navigate = useNavigate();

  const [compareType, setCompareType] = React.useState("");
  const [compareOption1, setCompareOption1] = React.useState("");
  const [compareOption2, setCompareOption2] = React.useState("");

  const [donor, setDonor] = useState<string[]>([]);
  const [sector, setSector] = useState<string[]>([]);
  const [region, setRegion] = useState<string[]>([]);

  const [disabled, setDisabled] = React.useState(true);
  const noSelect = [""];

  useEffect(() => {
    if (openDrawer) {
      Api.get(APIs.EAD_UNIQUE_LIST).then((payload: any) => {
        payload.donor.unshift("Select Donor");
        payload.sector.unshift("Select Sector");
        payload.region.unshift("Select Region");
        setDonor(payload.donor);
        setRegion(payload.region);
        setSector(payload.sector);
      });
    }
  }, [openDrawer]);

  function handleChange(event: any) {
    if (event.target.value !== "noselect") {
      setCompareType(event.target.value);
      setDisabled(false);
    } else {
      setCompareType(event.target.value);
      setDisabled(true);
    }
  }

  let type: any = null;

  /** This will be used to create set of options that user will see */
  let options: any = null;

  /** Setting Type variable according to dropdown */
  if (compareType === "noselect") type = noSelect;
  if (compareType === "donor") type = donor;
  else if (compareType === "sector") type = sector;
  else if (compareType === "region") type = region;

  /** If "Type" is null or undefined then options will be null,
   * otherwise it will create a options iterable based on our array
   */
  if (type)
    options = type.map((el: string) => (
      <option value={el.indexOf("Select") === -1 ? el.replace("/", "$") : ""}>
        {el}
      </option>
    ));

  const handleCloseDrawer = () => {
    setCompareType("");
    setCompareOption1("");
    setCompareOption2("");
    setDisabled(true);
    handleClose();
  };

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor="top"
        sx={{ width: "100%" }}
        open={openDrawer}
        onClose={() => handleCloseDrawer()}
        onOpen={() => console.log("open")}
        hideBackdrop={true}
      >
        <Box
          sx={{
            width: "100vw",
            height: "contentFit",
            padding: 1,
            backgroundColor: "lightgray",
          }}
          role="presentation"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3>COMPARISON</h3>
            <label>
              {/* <p>First Dropdown: (*)</p> */}
              <select
                onChange={handleChange}
                style={{ width: "100%", height: "30px", paddingLeft: 10 }}
              >
                <option selected value="noselect">
                  Please select
                </option>
                <option value="donor">Donor</option>
                <option value="sector">Sector</option>
                <option value="region">Region</option>
              </select>
            </label>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <select
                onChange={(e) => {
                  console.log(e.target.value);
                  if (e.target.value.indexOf("Select") === -1)
                    setCompareOption1(e.target.value);
                  else setCompareOption1("");
                }}
                style={{ width: "50%", height: "30px", paddingLeft: 10 }}
                disabled={disabled}
              >
                {options}
              </select>

              <select
                onChange={(e) => {
                  console.log(e.target.value.indexOf("Select"));
                  if (e.target.value.indexOf("Select") === -1)
                    setCompareOption2(e.target.value);
                  else setCompareOption2("");
                }}
                style={{
                  width: "50%",
                  height: "30px",
                  paddingLeft: 10,
                  marginLeft: "50px",
                }}
                disabled={disabled}
              >
                {options}
              </select>
            </Box>
            <br />
            <div
              style={{
                padding: "1px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{
                  fontSize: 20,
                  borderRadius: 5,
                }}
                disabled={disabled}
                onClick={() => {
                  if (compareOption1 !== "" && compareOption2 !== "") {
                    handleCloseDrawer();
                    navigate(
                      `/compare?type=${compareType}&o1=${compareOption1}&o2=${compareOption2}`
                    );
                  } else {
                    handleCloseDrawer();
                  }
                }}
              >
                Compare
              </button>
              <button
                onClick={handleCloseDrawer}
                style={{
                  fontSize: 20,
                  marginLeft: "20px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: 5,
                }}
              >
                Close
              </button>
            </div>
          </Box>
        </Box>
      </SwipeableDrawer>
    </React.Fragment>
  );
}

export default CompareDrawer;
