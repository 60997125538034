import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    userInfo: {},
    profilePicURL: null,
    refreshToken: null,
    accessToken: null,
    userProjects: [],
    newsCount: 0,
  },
  reducers: {
    setUserProjects: (state, action) => {
      state.userProjects = action.payload;
    },
    loginUser: (state, action) => {
      const { payload } = action;
      if (payload) {
        state.isAuthenticated = true;
        state.refreshToken = payload.refreshToken;
        state.accessToken = payload.accessToken;
        state.userInfo = payload.userInfo;

        localStorage.setItem("isAuthenticated", String(true));
        localStorage.setItem("refresh", payload.refreshToken);
        localStorage.setItem("userInfo", JSON.stringify(payload.userInfo));
      }
    },
    logoutUser: (state, action) => {
      state.isAuthenticated = false;
      state.refreshToken = null;
      state.accessToken = null;
      state.userInfo = null;
      state.userProjects = [];

      state.newsCount = 0;

      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userInfo");
    },
    setNewsCount: (state, action) => {
      state.newsCount = action.payload;
    },
  },
});

export const { loginUser, logoutUser, setUserProjects, setNewsCount } =
  AuthSlice.actions;
export const authReducer = AuthSlice.reducer;
