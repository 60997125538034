import { Navigate, Outlet, useLocation } from "react-router-dom";
import Navbar from "../../../components/Navbar";
import { useAppSelector } from "../../../store";

const PrivateRoute = () => {
  const isAuth = useAppSelector((state) => state.auth.accessToken);
  let location = useLocation();
  if (!isAuth) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return (
    <div>
      <Navbar />
      <Outlet />
    </div>
  );
};

export default PrivateRoute;
