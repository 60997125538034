import { useDispatch, useSelector } from "react-redux";
import "../../../static/css/Snackbar.css";
import { snackbarActions } from "../models/SnackbarSlice";

export const Snackbar = () => {
  const msg = useSelector((state) => state?.snackbar.msg);
  const open = useSelector((state) => state?.snackbar.open);
  const dispatch = useDispatch();
  open &&
    setTimeout(function () {
      dispatch(snackbarActions.hideSnackbar());
    }, 3000);

  return (
    <div id="snackbar" className={open ? "show" : ""}>
      {msg}
    </div>
  );
};
