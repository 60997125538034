import { createSlice } from "@reduxjs/toolkit";

export const BaseSlice = createSlice({
  name: "base",
  initialState: {
    topAppbarContents: null,
    topAppbarHeight: -1,
    leftAppbarWidth: 80,
    rightAppbarWidth: 80,
    contentDivHeight: 0,
    paperHeight: 0,
    openTopDrawer: false,
    companyInfo: null,
  },
  reducers: {
    setOpenTopDrawer: (state, action) => {
      state.openTopDrawer = action.payload;
    },
    setTopAppbarHeight: (state, action) => {
      state.topAppbarHeight = action.payload;
    },
    setLeftAppbarWidth: (state, action) => {
      state.leftAppbarWidth = action.payload;
    },
    setContentDivHeight: (state, action) => {
      state.contentDivHeight = action.payload;
      state.paperHeight = action.payload - 30;
    },
    setPaperHeight: (state, action) => {
      state.paperHeight = action.payload;
    },
    setTopAppbarContents: (state, action) => {
      state.topAppbarContents = action.payload;
    },
    setCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },
  },
});

export const baseActions = BaseSlice.actions;
export const baseReducer = BaseSlice.reducer;
