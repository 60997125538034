import {createSlice} from "@reduxjs/toolkit";


const CompareSlice = createSlice({
    name: "compare",
    initialState: {
        type: null,
        options: [],
    },
    reducers: {
        setCompareParams: (state, action) => {
            state.type = action.payload.type
            state.options = action.payload.options
         }
    }
})

export const compareActions = CompareSlice.actions;

export const copareReducer = CompareSlice.reducer;
