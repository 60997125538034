import * as React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import AddToHomeScreen from "./components/AddToHomeScreen";
import ContentRouter from "./modules/base/components/ContentRouter";
import { Snackbar } from "./modules/base/components/Snackbar";
import store, { persistor } from "./store";

function App() {
  // const topbarRef: any = useRef(null)
  return (
    <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <div
              style={{
                backgroundColor: "aaa",
                top: "0px",
                left: "0px",
                height: "100vh",
              }}
            >
              <ContentRouter />

              {/* <LeftMenuBar/>
                        <main id="dashboard-div" className="dashboard" style={{marginLeft: "0px"}}>
                            <TopAppbar/>
                            <ContentRouter/>
                        </main>  */}
            </div>
            <Snackbar />
            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
            <AddToHomeScreen />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.Fragment>
  );
}

export default App;
