import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FeedIcon from "@mui/icons-material/Feed";
import HomeIcon from "@mui/icons-material/Home";
import LibraryIcon from "@mui/icons-material/LocalLibrary";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  ClickAwayListener,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../static/img/logo.svg";
import CompareDrawer from "./CompareDrawer";
import ManageOptions from "./ManageOptions";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

function Navbar(props: Props) {
  const { window } = props;
  const navigate = useNavigate();
  const matcheSM = useMediaQuery("(max-width:1200px)");
  const location = useLocation();
  const companyInfo = useSelector((state: any) => state.base.companyInfo);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openDrawer, setOpenDrawe] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleNavigate = (link: string) => {
    handleDrawerToggle();
    navigate(link);
  };
  console.log({ mobileOpen });

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={{ padding: "10px" }}>
        <Link to={"/dashboard"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Avatar
              src={logo}
              alt="logo"
              style={{
                width: matcheSM ? 40 : 50,
                height: matcheSM ? 40 : 50,
                marginRight: "8px",
              }}
            />
            <Typography
              variant="h6"
              sx={{ color: "green", fontSize: matcheSM ? "16px" : "18px" }}
            >
              {companyInfo?.company_name}
            </Typography>
          </Box>
        </Link>
      </Box>
      <Divider />
      <List>
        <ListItem
          disablePadding
          onClick={() => {
            handleNavigate("/dashboard");
          }}
        >
          <ListItemButton
            sx={{
              color: "#333",
              fontWeight: "bold",
              borderRadius: 0,
              borderBottom: location.pathname.includes("dashboard")
                ? "2px solid #fff"
                : 0,
              padding: "0px 5px",
              fontSize: "18px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "0px",
                color: "inherit",
                marginRight: "10px",
              }}
            >
              <HomeIcon sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            <ListItemText
              primary="Home"
              sx={{
                "& span": {
                  color: "inherit",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          onClick={() => {
            handleNavigate("/news");
          }}
        >
          <ListItemButton
            sx={{
              color: "#333",
              fontWeight: "bold",
              borderRadius: 0,
              borderBottom: location.pathname.includes("news")
                ? "2px solid #fff"
                : 0,
              padding: "0px 5px",
              fontSize: "18px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "0px",
                color: "inherit",
                marginRight: "10px",
              }}
            >
              <FeedIcon sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            <ListItemText
              primary="News"
              sx={{
                "& span": {
                  color: "inherit",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          onClick={() => {
            handleNavigate("/searchprojects");
          }}
        >
          <ListItemButton
            sx={{
              color: "#333",
              fontWeight: "bold",
              borderRadius: 0,
              borderBottom: location.pathname.includes("searchprojects")
                ? "2px solid #fff"
                : 0,
              padding: "0px 5px",
              fontSize: "18px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "0px",
                color: "inherit",
                marginRight: "10px",
              }}
            >
              <LibraryIcon sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            <ListItemText
              primary="Search Projects"
              sx={{
                "& span": {
                  color: "inherit",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          onClick={() => {
            handleNavigate("/organogram");
          }}
        >
          <ListItemButton
            sx={{
              color: "#333",
              fontWeight: "bold",
              borderRadius: 0,
              borderBottom: location.pathname.includes("organogram")
                ? "2px solid #fff"
                : 0,
              padding: "0px 5px",
              fontSize: "18px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "0px",
                color: "inherit",
                marginRight: "10px",
              }}
            >
              <LibraryIcon sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            <ListItemText
              primary="Organogram"
              sx={{
                "& span": {
                  color: "inherit",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          onClick={() => {
            setMobileOpen(false);
            setOpenDrawe(true);
          }}
        >
          <ListItemButton
            sx={{
              color: "#333",
              fontWeight: "bold",
              borderRadius: 0,
              borderBottom: location.pathname.includes("dashboard")
                ? "2px solid #fff"
                : 0,
              padding: "0px 5px",
              fontSize: "18px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "0px",
                color: "inherit",
                marginRight: "10px",
              }}
            >
              <CompareArrowsIcon sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            <ListItemText
              primary="Compare Stats"
              sx={{
                "& span": {
                  color: "inherit",
                  fontSize: "inherit",
                  fontWeight: "inherit",
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", paddingBottom: "70px" }}>
      {openDrawer && (
        <CompareDrawer
          openDrawer={openDrawer}
          handleClose={() => setOpenDrawe(false)}
        />
      )}

      <AppBar
        component="nav"
        position="fixed"
        style={{ background: "#fff" }}
        elevation={5}
      >
        <Toolbar>
          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              display: matcheSM ? "block" : "none",
              color: "#222",
              marginRight: "10px",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <Link to={"/dashboard"}>
              <Box
                sx={{ display: "flex", alignItems: "center", color: "#000" }}
              >
                <Avatar
                  src={logo}
                  alt="logo"
                  style={{
                    width: matcheSM ? 40 : 50,
                    height: matcheSM ? 40 : 50,
                    marginRight: "8px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: matcheSM ? "14px" : "18px",
                    lineHeight: "16px",
                  }}
                >
                  {companyInfo?.company_name}
                </Typography>
              </Box>
            </Link>
          </Box>
          <Box sx={{ display: matcheSM ? "none" : "block" }}>
            <Link to="/dashboard">
              <Button
                sx={{
                  fontWeight: "bold",
                  color: "#222",
                  borderRadius: 0,
                  borderBottom: location.pathname.includes("dashboard")
                    ? "2px solid #28a745"
                    : 0,
                  padding: "0px 5px",
                }}
                startIcon={<HomeIcon />}
                variant="text"
              >
                Home
              </Button>
            </Link>
            <Link to="/news">
              <Button
                sx={{
                  color: "#222",
                  fontWeight: "bold",
                  borderRadius: 0,
                  padding: "0px 5px",
                  marginLeft: "20px",
                  borderBottom: location.pathname.includes("news")
                    ? "2px solid #28a745"
                    : 0,
                }}
                startIcon={<FeedIcon />}
                variant="text"
              >
                News
              </Button>
            </Link>
            <Link to="/searchprojects">
              <Button
                sx={{
                  color: "#222",
                  fontWeight: "bold",
                  borderRadius: 0,
                  padding: "0px 5px",
                  marginLeft: "20px",
                  borderBottom: location.pathname.includes("searchprojects")
                    ? "2px solid #28a745"
                    : 0,
                }}
                startIcon={<LibraryIcon />}
                variant="text"
              >
                Search Projetcs
              </Button>
            </Link>
            <Link to="/organogram">
              <Button
                sx={{
                  color: "#222",
                  fontWeight: "bold",
                  borderRadius: 0,
                  padding: "0px 5px",
                  marginLeft: "20px",
                  borderBottom: location.pathname.includes("organogram")
                    ? "2px solid #28a745"
                    : 0,
                }}
                startIcon={<LibraryIcon />}
                variant="text"
              >
                Organogram
              </Button>
            </Link>
            <Button
              sx={{
                color: "#222",
                fontWeight: "bold",
                borderRadius: 0,
                padding: "0px 5px",
                marginLeft: "20px",
              }}
              startIcon={<CompareArrowsIcon />}
              variant="text"
              onClick={() => setOpenDrawe(true)}
            >
              Compare Stats
            </Button>
            <Link to="/search-jmcs">
              <Button
                sx={{
                  color: "#222",
                  fontWeight: "bold",
                  borderRadius: 0,
                  padding: "0px 5px",
                  marginLeft: "20px",
                  borderBottom: location.pathname.includes("search-jmcs")
                    ? "2px solid #28a745"
                    : 0,
                }}
                startIcon={<LibraryIcon />}
                variant="text"
              >
                JMC
              </Button>
            </Link>
            <ManageOptions />
          </Box>
          <Box sx={{ display: matcheSM ? "block" : "none" }}>
            <ManageOptions />
          </Box>
        </Toolbar>
      </AppBar>

      {mobileOpen && (
        <ClickAwayListener onClickAway={() => setMobileOpen(false)}>
          <Paper
            sx={{
              width: "250px",
              position: "fixed",
              height: "100vh",
              top: 0,
              left: 0,
              zIndex: 1300,
              background: "#fff",
            }}
            elevation={4}
          >
            {drawer}
          </Paper>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export default Navbar;
