import "./styles.css";

const AddToHomeScreen = () => {
  return (
    <div className="block__install" id="BlockInstall">
      <div className="inner">
        {/* <div className="close" id="BlockInstallClose">
          <span>
            <img src="/ead.png" alt="Ead logo" />
          </span>
        </div> */}
        <div className="logo">
          <img src="/ead.png" alt="Ead logo" />
        </div>
        <div className="name">
          <span className="title">ECONOMIC AFFAIR DIVISION</span>
          <span className="description">PMU-FFP</span>
        </div>
        <div className="cta">
          <button id="BlockInstallButton" className="btn btn-outline">
            Install
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddToHomeScreen;
