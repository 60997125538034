import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogBoxProps {

}

interface DialogBoxState {
    open: boolean
    heading: string
    appbarButtons: JSX.Element
    contents: JSX.Element
}

class DialogBox extends React.PureComponent<DialogBoxProps, DialogBoxState> {
    constructor(props: DialogBoxProps) {
        super(props);
        this.state = {
            open: false,
            heading: "EAD Projects",
            appbarButtons: <React.Fragment/>,
            contents: <React.Fragment/>
        }
    }

    openDialog(heading: string) {
        this.setState({open: true, heading: heading})
    };

    closeDialog() {
        this.setState({open: false})
    };

    setAppbarButtons(buttons: JSX.Element) {
        this.setState({appbarButtons: buttons})
    }

    setContent(content: JSX.Element) {
        this.setState({contents: content})
    }

    render() {
        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.closeDialog.bind(this)}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{position: 'relative'}} style={{ background: 'black' }} >
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.closeDialog.bind(this)}
                                aria-label="close"
                            >
                                <CloseIcon/>
                            </IconButton>
                            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                                {this.state.heading}
                            </Typography>
                            {this.state.appbarButtons}
                            <Button autoFocus color="inherit" onClick={this.closeDialog.bind(this)}>
                                Close
                            </Button>
                        </Toolbar>
                    </AppBar>
                    {this.state.contents}
                    {/*<List>*/}
                    {/*    <ListItem button>*/}
                    {/*        <ListItemText primary="Phone ringtone" secondary="Titania"/>*/}
                    {/*    </ListItem>*/}
                    {/*    <Divider/>*/}
                    {/*    <ListItem button>*/}
                    {/*        <ListItemText*/}
                    {/*            primary="Default notification ringtone"*/}
                    {/*            secondary="Tethys"*/}
                    {/*        />*/}
                    {/*    </ListItem>*/}
                    {/*</List>*/}
                </Dialog>
            </div>
        );
    }
}

export default DialogBox;
