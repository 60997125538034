import Api from "../../../Api";
import store from "../../../store";
import { logoutUser } from "../../auth/models/AuthSlice";
import { snackbarActions } from "../models/SnackbarSlice";

class CommonUtils {
  static toCapitalizeCase = (text) => {
    return (
      text.toLowerCase().charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    );
  };

  static logoutUser() {
    store.dispatch(logoutUser(null));
    window.location.href = "/";
  }

  static getPaperHeight(headerHeight, rowPadding) {
    const contentDivHeight = store.getState().base.contentDivHeight;
    return contentDivHeight - 2.5 * rowPadding - headerHeight - 5;
  }

  static checkUserAuthentication() {
    Api.getAccessToken().then((response) => {
      console.log("access token", response);
      // if (response) {
      //     store.dispatch(setAccessToken(response));
      //     store.dispatch(setAuthentication(true));
      // }
    });
  }

  static getUserInfo() {
    let userInfo = store.getState().auth.userInfo;
    console.log("UserInfo: ", userInfo);
    return typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;
  }

  static showSnackbar(msg, alertType) {
    store.dispatch(
      snackbarActions.showSnackbar({ msg: msg, alertType: alertType })
    );
  }
}

export default CommonUtils;
